.areas-contenedor {
    margin: 9px auto;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    border: 1px solid;
    padding: 15px 1px 10px 1px;
    position: relative;
    border-radius: 4px;
    flex-wrap: wrap;
}

.areas-contenedor h4 {
    position: absolute;
    top: -18px;
    left: 10px;
    background-color: white;
}

.areas-prearea {
    display: flex;
    flex-direction: row;
    position: relative;
    margin: 9px 1px;
}
.areas-remove {
    position: absolute;
    left: 15px;
    top: 3px;
}
.areas-area {
    width: fit-content;
    margin: 1px 7px;
    padding: 3px 7px;
    border-radius: 4px;
    cursor: pointer;
    box-shadow: 1px 1px 6px 2px #00000029;
    padding-left: 30px;
}
.areas-area:hover {
    box-shadow: 1px 1px 6px 2px #00000052;
}

.numerar .panel {
    /* background-color: orange; */
    border: 1px solid;
    padding: 7px;
    border-radius: 6px;
    display: flex;
    justify-content: space-around;
    margin: 5px auto;
    flex-wrap: wrap;
}
.numerar .panel button {
    display: block;
    margin: 2px auto;
    flex: 22%;
}
.numerar .panel div {
    display: flex;
    align-items: center;
}