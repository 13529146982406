.login-bg {
    position: relative;
    min-height: 100vh;
    min-width: 100%;
    background: rgb(255, 198, 138);
    background: radial-gradient(circle, rgba(255, 198, 138, 1) 0%, rgba(227, 123, 0, 1) 65%);
}

.login-box {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    padding: 20px;
    border-radius: 2px;
    display: flex;
    flex-direction: column;
    align-items: center;
}

.login-box img {
    margin: 10px;
}

@media only screen and (max-width: 600px) {
    .login-box {
        width: 80%;
    }
}