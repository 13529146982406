.pagos-area .pa-header {
    display: flex;
    justify-content: flex-start;
    margin-top: 20px;
}

.pagos-area .pa-header h3 {
    font-weight: 900;
    font-size: 1.2em;
}

.pagos-area .pa-acciones {
    padding: 0px 6px;
}

.pagos-area .pa-acciones button {
    margin: auto 10px;
}

.pagos-area .pa-producto {
    display: flex;
    flex-wrap: wrap;
}

.pagos-area .pa-producto .pa-label {
    width: 180px;
    min-width: 180px;
    text-align: left;
    margin-right: 15px;
}
.pagos-area .pa-producto .pa-label span {
    padding: 2px 8px;
    border-radius: 4px;
    background-color: transparent;
    color: rgb(227, 123, 0);
    font-weight: bolder;
}

.pagos-area .pa-producto {
    margin: 10px auto;
}
.pagos-area .pa-producto p {
    margin: 0px;
}

.pagos-area .pa-producto .pa-precios {
    display: flex;
    flex-wrap: wrap;
}

.pagos-area .pa-producto .pa-precios .pa-precio {
    min-width: 55px;
    margin: 0px 4px;
    margin-bottom: 10px;
}

.pagos-area .pa-producto .pa-precios .pa-precio p {
    border: 1px solid black;
    padding: 1px 4px;
    border-radius: 13px;
    text-align: right;
    position: relative;
}
.pagos-area .pa-producto .pa-precios .pa-precio span {
    position: absolute;
    bottom: -6px;
    font-weight: bolder;
    font-size: 0.7em;
    background-color: white;
    left: 0px;
    line-height: 1;
}

.pagos-area .pa-producto .pa-precios {
    display: flex;
    flex-wrap: wrap;
}

.pagos-area .pa-producto .pa-precios .pa-forma {
    min-width: 150px;
    margin: 0px 4px;
    margin-bottom: 10px;
}

.pagos-area .pa-producto .pa-precios .pa-forma p {
    border: 1px solid black;
    padding: 1px 4px;
    border-radius: 13px;
    text-align: right;
    position: relative;
}
.pagos-area .pa-producto .pa-precios .pa-forma span {
    position: absolute;
    bottom: -6px;
    font-weight: bolder;
    font-size: 0.7em;
    background-color: white;
    left: 0px;
    line-height: 1;
}


.pagos-area .pa-producto .pa-producto-acciones {
    margin-left: 5px;
}

.pagos-area .pa-producto .pa-producto-acciones button {
    margin: 0px 2px;
}

.forma-select {
    padding: 0px!important;
}
