.cambio-precios-area h3 {
  font-weight: bolder;
  font-size: 1.2em;
  margin: 12px auto 1px auto;
}

.cambio-precios-area .producto {
  display: flex;
  margin: 8px auto;
}

.cambio-precios-area .producto .nombre {
  font-weight: bold;
  min-width: 150px;
}

.cambio-precios-area .producto .precios {
  display: flex;
}
.precio-individual {
  position: relative;
  margin: 1px 3px;
}
.precio-individual input {
  border: 1px solid rgb(227, 123, 0);
  width: 60px;
  border-radius: 8px;
  text-align: right;
  outline: none;
}
.precio-individual label {
  position: absolute;
  bottom: -9px;
  left: 5px;
  font-size: 0.7em;
  font-weight: bold;
  background-color: white;
}