/* SIDE */

.side .bg {
    position: fixed;
    top: 0;
    right: -100%;
    height: 100vh;
    width: 100%;
    z-index: 100;
    background-color: rgba(0, 0, 0, 0);
    /* display: none; */
    transition: right 0.2s, background-color 1s;
}

.side .bg.open {
    /* display: inline; */
    right: 0;
    background-color: rgba(0, 0, 0, 0.281);
}

.side .side {
    position: fixed;
    top: 0;
    left: -200px;
    height: 100vh;
    width: 200px;
    background-color: white;
    z-index: 101;
    transition: left 0.5s;
    overflow-y: scroll;
    overflow-x: hidden;
}

.side .side.open {
    left: 0;
}

.side .icon {
    position: fixed;
    top: 0;
    left: 10px;
    padding-top: 0px;
    z-index: 102;
    transition: left 0.5s;
}

.side .icon i {
    background-color: white;
    padding: 2px;
    /* border-radius: 5px; */
    border-bottom-right-radius: 5px;
}

.side .icon i.icon-close {
    border: 5px solid #ffedd7;
    border-left: none;
    border-top: none;
}

.side .icon.open {
    left: 200px;
}

.side ::-webkit-scrollbar {
    width: 5px
}

.inicio-route {
    padding: 50px 20px;
}

@media (min-width: 800px) {
    .inicio-route {
        padding: 10px 20px 50px 210px;
        /* display: none; */
    }
    .side .icon {
        display: none;
    }
    .side .side {
        left: 0;
    }
}
.side .buttons {
    width: 100%;
    display: flex;
    justify-content: center;
    margin-bottom: 15%;
}
.side .buttons button {
    margin: 1px 8px;
    width: 40%;
}

.side .logo {
    text-align: center;
    padding-top: 10px;
}
.side .logo img {
    width: 170px;
}

.side .nombre {
    text-align: center;
}
.side .nombre h2 {
    margin: 3px auto;
}

.side .prop p {
    margin: 0px;
    margin-bottom: 10px;
    text-align: center;
    font-weight: bold;
}