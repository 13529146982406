@import 'react-trumbowyg/dist/trumbowyg.min.css';
@import '~antd/dist/antd.css';

/* body {
  font-family: 'Lato', sans-serif;
} */

.row {
  margin: 0 !important;
}

.anticon {
  vertical-align: 2px !important; 
}

.ant-modal-body {
  overflow-y: auto;
  overflow: auto !important;
}